$header: 69px;
$footer: 112px;
$color-primary: #00adee;

html{
	scroll-behavior: smooth;
}

.footer{
	text-align: center;
	z-index: 2;
	.social{
		.icon{
			font-size: 29px;
			padding: 0 15px 0 0;
		}
	}
}
.emptyLayout, .mainLayout{
	.ant-layout-content{
		background: #fafafa;
		min-height: calc(100vh - #{$footer})!important;
		font-family: Roboto-Regular, Helvetica, Arial, sans-serif;	
	}
}

.emptyLayout{
	.ant-layout-content{
		background: #00adee7a;
		min-height: calc(100vh - #{$footer})!important;
		position: relative;
	}
}

.menu{
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 999;
	color: white;
	font-size: 25px;
}


.menuWrapper{
	width: 200px;
	.ant-menu-item-selected{
		background-color: #00adee9e!important;
		color: #fff!important;
	}
	.ant-popover-inner-content{
		padding: 0;
		border-right: none;
	}
}

.headding{
	height: $header;
	width: 100%;
	background-color: $color-primary;
}


.ant-btn{
	background-color: $color-primary!important;
	color: #fff!important;
	border-color: $color-primary!important;
	
}
.ant-input{
	&:focus{
		border-color: $color-primary!important;
		-webkit-box-shadow: 0 0 0 2px #00adee33!important;
		box-shadow: 0 0 0 2px #00adee33!important;
	}
}