.contact{
	.contactPlane{
		padding: 50px;
		text-align: center;
		img{
			width: 100px;
		}
	}
	.title, .subTitle{
		text-align: center;
		font-size: 40px;
	}
	.subTitle{
		font-size: 30px;
	}
	.form{
		padding: 30px 0;
		.ant-form-item{
			padding: 0 5px;
		}
		.submit{
			text-align: center;
			padding: 0 5px;
			.ant-btn {
				width: 100%;
			}
		}
	}
	.contactInformation{
		text-align: center;
		.anticon{
			color: $color-primary;
		}
		a{
			color: #5d5e5f;
			&:hover{
				color: $color-primary;
			}
		}
	}
}