.notFound{
	min-height: calc(100vh - #{$footer});
	z-index: 2;
	.ant-result-title{
		color: #fff;
	}
	
	@keyframes diagonal {
		0% { transform: translatex(0) translatey(0)}
		50% { transform: translatex(45vw) translatey(-60vh) rotate(-45deg) scaleX(-1)}
		100% { transform: translatex(0) translatey(-100vh)  rotate(-45deg) scaleX(-1)}
	}
	@keyframes diagonal2 {
		0% { transform: translatex(100vw) translatey(0) scaleX(-1)}
		50% { transform: translatex(57vw) translatey(-50vh)}
		100% { transform: translatex(100vw) translatey(-100vh)}
	}
	@keyframes diagonal3 {
		0% { transform: translatex(30vw) translatey(0) rotate(-45deg)}
		50% { transform: translatex(30vw) translatey(-50vh)}
		100% { transform: translatex(50vw) translatey(-100vh)}
	}
	.plane{
		position: absolute;
		z-index: 1;
		width: 100px;
		left: 0;
		bottom: -50px;
		&.fly1{
			-webkit-animation: diagonal 15s infinite;
			-moz-animation: diagonal 15s infinite;
			-o-animation: diagonal 15s infinite;
			animation: diagonal 15s infinite;
		}
		&.fly2{
			-webkit-animation: diagonal2 10s infinite;
			-moz-animation: diagonal2 10s infinite;
			-o-animation: diagonal2 10s infinite;
			animation: diagonal2 10s infinite;
			animation-delay: 2s;
			width: 50px;
		}
		&.fly3{
			-webkit-animation: diagonal3 10s infinite;
			-moz-animation: diagonal3 10s infinite;
			-o-animation: diagonal3 10s infinite;
			animation: diagonal3 10s infinite;
			animation-delay: 4s;
			width: 30px;
		}
	}
}

