.slider {
	width: 100%;
	position: relative;
	text-align: center;
	background: #000;
	
	.overlay-black{
		width: 100vw;
		height: 100vh;
		background: #000;
		position: absolute;
		top: 0;
		opacity: 0.3;
	}
	.overlay-blue{
		width: 100vw;
		height: 100vh;
		background: #00adee;
		position: absolute;
		top: 0;
		opacity: 0.5;
	}
	.slick-list{
		height: 100vh;
	}
	.logo-wrapper{
		width: 100%;
		position: absolute;
		top: 0;
		height: 100vh;
		.logo {
			width: 30vw;
			height: 100%;
		}
		@media only screen and (max-width: 960px) {
		/* For mobile phones: */
			.logo {
				width: 70vw;
			}
		}
	}
	.img{
		width: 100vw;
		height: 100vh;
		filter: blur(4px);
		-webkit-filter: blur(4px);
		background-size: cover;
		background-position: center;
		background-image: url(../images/banner1.jpg);
	}
	.goDown{
		position: absolute;
		bottom: 0;
		color: #00adee;
		font-size: 30px;
		width: 100%;
	}
}

.projects, .services{
	.mainCard{
		text-align: center;
	}
	.title{
		font-size: 30px;
		padding: 20px;
		h1{
			color: #595959;
		}
	}
}

.services{
	background: white;
	padding: 0 30px 30px;
	.serviceIcon{
		font-size: 60px;
	}
	.serviceCard{
		text-align: center;
		.ant-card-meta-detail{
			width: 100%;
		}
		&:hover{
			.ant-card-meta-title, .serviceIcon{
				color: #01aeef;
			}
		}
	}
}

.projects{
	.img{
		width: 100%;
	}
	.ant-card-cover{
		cursor: pointer;
	}
	.ant-card-meta-detail{
		width: 100%;
	}
}

.ownership{
	width: 100vw;
	position: relative;
	.ownCard{
		height: 300px;
		width: 100%;
		overflow: hidden;
		.img{
			height: 100%;
			filter: blur(3px);
			-webkit-filter: blur(3px);
			background-size: cover;
			background-repeat: no-repeat;
			&.left{
				background-image: url(../images/inspiration/left.jpeg);
				background-position: center 40%; 
			}
			&.starting{
				background-image: url(../images/inspiration/starting.jpg);
				background-position: center; 
			}
			&.right{
				background-image: url(../images/inspiration/right.jpeg);
				background-position: center 45%; 
			}

		}
	}
	.disclamer{
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 40px;
		color: white;
		top: calc(50% - 20px);
	}
}