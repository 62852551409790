.about {

	.title {
		text-align: center;
		font-size: 30px;
		padding: 20px;
		h1{
			color: #000000a6;
		}
	}
	
	.slogan{
		text-align: center;
		padding: 0 10px 40px;
		font-size: 18px;
	}
	.paragraph, .image {
		min-height: 400px;
		width: 100%;
	}

	.image {
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url(../images/history.jpg);
		background-position: center;
	}
	.paragraph {
		background: #f0f2f5;
		color: #000000a6;
		padding: 30px;

		p{
			text-align: justify;
			font-size: 16px;
			padding: 10px;
		}

		.ant-divider-inner-text {
			font-size: 30px;
			color: #000000a6;
			padding: 0 10px;
		}

		.ant-divider-horizontal.ant-divider-with-text-left::before,
		.ant-divider-horizontal.ant-divider-with-text-left::after {
			border-top: 1px solid #00adee;
		}
	}
	.team{
		padding: 50px 0;
		text-align: center;
		.party{
			text-align: center;
			font-size: 30px;
			padding: 0 20px;
		}
		.member{
			.img{
				text-align: center;
				padding-top: 10px;
				img{
					border-radius: 50%;
					height: 250px;
					box-shadow: 0px -4px 13px 0px #61616166;
				}
			}

			.name, .career{
				text-align: center;
				padding-top: 5px;
			}

			.name{
				font-weight: bold;
				font-size: 18px;
			}

			.career{
				font-size: 14px;
				color: $color-primary;
			}
		}
	}
}