@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pof3iz');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pof3iz#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?pof3iz') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?pof3iz') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?pof3iz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram {
  &:before {
    content: $icon-instagram;     
    color: #e4405f;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;     
    color: #1877f2;
  }
}
.icon-web {
  &:before {
    content: $icon-web; 
  }
}
.icon-fingerprint {
  &:before {
    content: $icon-fingerprint; 
  }
}
.icon-paint-brush {
  &:before {
    content: $icon-paint-brush; 
  }
}

